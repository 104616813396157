import { Collection } from '@byll/hermes'
import { dispose, Disposer } from '@byll/hermes/lib/helpers/Disposer'
import { observer } from 'mobx-react'
import * as React from 'react'
import { AppContext, AppContextProps } from 'services/connection/models/AppContext'
import { IResidentLetterEntry } from 'contracts/residents/interfaces/IResidentLetterEntry'
import { Model } from 'components/Form/Model'
import { dayjs } from 'contracts/general/helpers/dayjs'
import { ResidentsList } from './components/LetterList'
import { InputCompound } from 'components/Form/components/InputCompound'
import { isStammCompound } from 'helpers/isStamm'
import { makeObservable, observable, reaction, runInAction } from 'mobx'
import { Button } from 'components/Form/components/Button'
import { CostCoverageDownloadDropdown } from 'modules/CostCoverages/components/CostCoverageDownloadDropdown'
import { createAndDownloadReport } from 'helpers/createAndDownloadReport'
import { IResidentLettersFilter } from 'contracts/residents/interfaces/IResidentLettersFilter'
import { InputBuilding } from 'components/Form/components/InputBuilding'
import { storage } from 'services/storage'
import { isLfgInstance } from 'contracts/general/helpers/instanceIds'

interface Props {}
const reports: { report: string; label: string }[] = [
  { report: 'postliste-jbp', label: 'JBP Postliste' },
  { report: 'postliste-intern', label: 'Interne Postliste' },
  { report: 'postliste-datenschutz', label: 'Datenschutzliste' },
]

@observer
export class ResidentLetters extends React.Component<Props, {}> {
  static contextType = AppContext
  private readonly model: Model<IResidentLettersFilter>
  private readonly entries: Collection<
    IResidentLetterEntry,
    { sort: string },
    IResidentLettersFilter
  >
  private readonly disposers: Disposer[] = []
  private readonly offerBuildingSelection: boolean
  @observable private downloading = false

  constructor(props: Props, context: AppContextProps) {
    super(props)
    const prev = storage.get(`residentLettersFilter.${context.user.id}`)
    this.offerBuildingSelection = !isLfgInstance(context.instance.id)
    this.model = new Model({
      compoundId: prev?.compoundId || context.defaults.responsibilityCompoundId,
      buildingId: prev?.compoundId && prev?.buildingId ? prev.buildingId : null,
      sort: 'receiver.name',
      mountedAt: dayjs().toISOString(),
    })
    if (
      context.permissions.menu_resident_letters === 1 &&
      !isStammCompound(this.model.values.compoundId || '')
    ) {
      this.model.values.compoundId = null
    }
    if (!this.offerBuildingSelection) {
      this.model.values.buildingId = null
    }
    this.entries = new Collection(
      `/api/${context.instance.id}/residentLetters`,
      this.model.values,
    )
    makeObservable(this)
  }

  componentDidMount() {
    this.disposers.push(this.entries.init({ observeQuery: true }))
    this.disposers.push(
      reaction(
        () => `${this.model.values.compoundId}-${this.model.values.buildingId}`,
        () => {
          storage.set(`residentLettersFilter.${this.context.user.id}`, {
            buildingId: this.model.values.buildingId || null,
            compoundId: this.model.values.compoundId || null,
          })
        },
      ),
    )
  }

  componentWillUnmount() {
    dispose(this.disposers)
  }

  private download = async (report: { report: string; label: string }) => {
    runInAction(() => (this.downloading = true))
    try {
      await createAndDownloadReport(
        report.report,
        this.context.instance.id,
        {
          compoundId: this.model.values.compoundId,
          buildingId: this.model.values.buildingId,
        },
        `${report.label}.xlsx`,
      )
    } catch (_e) {
      /* */
    }
    runInAction(() => (this.downloading = false))
  }

  render() {
    return (
      <div className='px-4 sm:px-6 pt-14 bg-gray-100 min-h-full'>
        {/* Section heading */}
        <div className='relative my-7 pb-5 sm:pb-0 bg-white sm:rounded-lg shadow p-6'>
          <div className='flex items-center justify-between pb-6'>
            <h3 className='text-lg leading-6 font-medium text-gray-900'>Postliste</h3>
            <InputCompound
              className='flex-auto ml-4'
              model={this.model}
              name='compoundId'
              onlyStamm={this.context.permissions.menu_resident_letters === 1}
              saveResponsibility
            />
            <InputBuilding
              className='flex-auto ml-4'
              model={this.model}
              name='buildingId'
              disabled={!this.model.values.compoundId || !this.offerBuildingSelection}
              emptyText='Geländeliste'
              onlyStamm={this.context.permissions.menu_resident_letters === 1}
              compoundId={this.model.values.compoundId}
            />

            <div className='flex-content ml-4'>
              {this.downloading && (
                <Button color='primary' style={{ width: 38, height: 38, padding: 0 }}>
                  <i className='fas fa-sync fa-spin' />
                </Button>
              )}
              {!this.downloading && (
                <CostCoverageDownloadDropdown
                  reports={reports}
                  onSelect={this.download}
                  style={{ borderRadius: 6 }}
                />
              )}
            </div>
          </div>
        </div>

        {/* Table */}
        <ResidentsList entries={this.entries} />
      </div>
    )
  }
}
