import { Collection, hermes, Resource } from '@byll/hermes'
import { LoadMoreButton } from 'components/LoadMoreButton'
import { RoundIcon } from 'components/RoundIcon'
import { Tooltip } from 'components/Tooltip'
import { NotAuthorizedError } from 'contracts/errors/HermesErrors'
import { DAYS } from 'contracts/general/helpers/days'
import { MONTHS_SHORT } from 'contracts/general/helpers/months'
import { ITodoSearchResult } from 'contracts/todos/interfaces/ITodoSearchResult'
import { ITodoSearchResultFilter } from 'contracts/todos/interfaces/ITodoSearchResultFilter'
import { dayjs } from 'helpers/dayjs'
import { sleep } from 'helpers/sleep'
import { makeObservable, observable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import { TicketRating } from 'modules/Dashboard/components/DashboardTickets/components/TicketRating'
import * as React from 'react'
import { toast } from 'react-toastify'
import { box } from 'services/box'
import { AppContext } from 'services/connection/models/AppContext'

interface Props {
  todos: Collection<
    ITodoSearchResult,
    any,
    { status: ITodoSearchResultFilter['status']; page: string }
  >
  navigate: (url: string) => void
  title?: string
  openInNewTab?: boolean
  showAssigner?: boolean
}

@observer
export class TodoOverlayList extends React.Component<Props, {}> {
  static contextType = AppContext
  @observable private checked = new Map<string, boolean>() // Overwrites of todo toggles (used during patch event)

  constructor(props: Props) {
    super(props)
    makeObservable(this)
  }

  private todoMapper = (res: Resource<ITodoSearchResult>) => {
    const todo = res.data
    if (!todo) {
      return null
    }
    return (
      <div
        key={todo.id}
        className='group hover:bg-indigo-100 bg-gray-100 rounded-md overflow-hidden relative cursor-pointer mb-3'
        onClick={() => this.openTodo(todo)}
      >
        {/* Row 1 */}
        <div className='truncate pl-2 pr-2 py-2 z-1 flex items-center'>
          <input
            type='checkbox'
            checked={
              this.checked.has(todo.id) ? this.checked.get(todo.id) : !!todo.doneDate
            }
            className={`rounded-full ${
              todo.doneDate ? 'border-0' : 'border border-gray-300'
            } h-5 w-5 text-green-500 mr-2`}
            onChange={(event) => {
              event.stopPropagation()
              this.toggleTodo(todo)
            }}
            onClick={(event) => event.stopPropagation()}
            disabled={
              todo.listId === this.context.defaults.ticketTodoListId ? true : false
            }
          />
          {!todo.readConfirmation || todo.assignees.length === 0 ? (
            <strong>{todo.label}</strong>
          ) : (
            todo.label
          )}
          {todo.listId === this.context.defaults.ticketTodoListId && (
            <span className='text-gray-400 ml-1 text-sm'>#{todo.id}</span>
          )}
          {todo.listId === this.context.defaults.actionTodoListId && (
            <span className='rounded-full text-xs bg-cyan-400 text-white py-[1px] px-2 ml-2'>
              Maßnahme
            </span>
          )}
          {todo.assignees.length === 0 && (
            <span className='inline-flex rounded-full align-middle w-[9px] h-[9px] ml-1 bg-indigo-500' />
          )}
          {!todo.readConfirmation && todo.assignees.length > 0 && (
            <span className='inline-flex rounded-full align-middle w-[9px] h-[9px] ml-1 bg-orange-600' />
          )}
          {todo.doneDate && (
            <span className='ml-2 inline-flex items-center px-2 rounded-full text-sm font-medium bg-gray-100 text-gray-800 border border-gray-500'>
              {dayjs(todo.doneDate).format('DD.MM.YYYY')}
            </span>
          )}
          {(todo.listId === this.context.defaults.ticketTodoListId ||
            todo.listId === this.context.defaults.actionTodoListId) &&
            todo.rating && (
              <div className='inline-flex ml-2'>
                <TicketRating key={todo.rating} rating={todo.rating} />
              </div>
            )}
          <RoundIcon
            classNameContainer='hidden group-hover:block'
            tooltip={{ text: 'Todo löschen', position: 'left' }}
            style={{ position: 'absolute', top: 5, right: 5 }}
            icon='fas fa-trash'
            color='danger'
            onClick={(event) => {
              event.stopPropagation()
              this.deleteTodo(todo)
            }}
          />
        </div>

        {/* Row 2 */}
        <div className='text-gray-500 text-xs pl-[36px] pr-2 pb-2 -mt-2 truncate'>
          {todo.dueDate && (
            <span className='text-red-500 mr-2'>
              {dayjs(todo.dueDate).format('DD.MM.YY')}
            </span>
          )}
          {todo.assigner && (
            <span className='mr-2 has-tooltip'>
              {`${todo.assigner.firstName} ${todo.assigner.lastName}`
                .split(/[ -]/g)
                .map((word) => word[0])
                .join('')
                .toUpperCase()}
              <Tooltip position='right'>{`Von ${todo.assigner.firstName} ${todo.assigner.lastName}`}</Tooltip>
            </span>
          )}
          {todo.notes || 'Keine Beschreibung'}
        </div>
      </div>
    )
  }

  private toggleTodo = async (todo: ITodoSearchResult) => {
    if (this.checked.has(todo.id)) {
      return
    } // Currently toggling
    try {
      runInAction(() => this.checked.set(todo.id, !todo.doneDate))
      await sleep(100) // Show the changed todo for a short time before it is removed from the list (and moved to another list - done/due) - network latency is added to this interval.
      await hermes.patch(
        `/api/${this.context.instance.id}/todoLists/${todo.listId}/todos/${todo.id}`,
        { doneDate: todo.doneDate ? null : dayjs().format('YYYY-MM-DD') },
      )
    } catch (_e) {
      void box.alert('Aufgabe', 'Diese Aufgabe konnte nicht geändert werden.', {
        color: 'danger',
      })
    }
    await sleep(100)
    runInAction(() => this.checked.delete(todo.id))
  }

  private openTodo = async (todo: ITodoSearchResult) => {
    if (
      todo.listId === this.context.defaults.ticketTodoListId ||
      todo.listId === this.context.defaults.actionTodoListId
    ) {
      if (window.location.pathname === '/') {
        this.props.navigate(`/tickets/${todo.listId}/${todo.id}`)
      } else {
        window.open(`/tickets/${todo.listId}/${todo.id}`, '_blank')
      }
      return
    }
    if (!todo.url) {
      void box.alert('Aufgabe', 'Diese Aufgabe kann nicht geöffnet werden.')
      return
    }
    if (this.props.openInNewTab) {
      window.open(todo.url, '_blank')
    } else {
      this.props.navigate(todo.url)
    }
  }

  private deleteTodo = async (todo: ITodoSearchResult) => {
    if (
      !(await box.alert(
        'Aufgabe löschen',
        'Möchten Sie diese Aufgabe wirklich unwiderruflich löschen?',
        { color: 'danger', confirm: 'Löschen', cancel: 'Abbrechen' },
      ))
    ) {
      return
    }
    try {
      await hermes.delete(
        `/api/${this.context.instance.id}/todoLists/${todo.listId}/todos/${todo.id}`,
      )
      toast.success('Aufgabe erfolgreich gelöscht.')
    } catch (e: any) {
      void box.alert(
        'Aufgabe',
        e?.id === NotAuthorizedError.id
          ? 'Sie haben nicht die nötige Berechtigung, um diese Aufgabe zu löschen.'
          : 'Diese Aufgabe konnte nicht gelöscht werden.',
        { color: 'danger' },
      )
    }
  }

  render() {
    const today = dayjs()

    // Not yet loaded
    if (
      !this.props.todos.resources ||
      !this.props.todos.metadata ||
      this.props.todos.resources.length === 0
    ) {
      return null
    }

    // Loaded
    return (
      <div className='border-b border-gray-300 last:border-0'>
        {/* Caption */}
        {this.props.title && (
          <div className='mb-4'>
            <span className='font-bold'>{this.props.title}</span>
            {this.props.todos.query.status === 'due' && (
              <span className='text-gray-500 ml-4'>{`${
                DAYS[today.day()]
              }. ${today.date()} ${MONTHS_SHORT[today.month()]}`}</span>
            )}
          </div>
        )}

        {/* List */}
        {this.props.todos.resources.map(this.todoMapper)}

        {/* Load more */}
        {this.props.todos.query.page && this.props.todos.metadata.hasMoreItems && (
          <div className='text-center text-sm'>
            {/* eslint-disable */}
            <LoadMoreButton
              collection={this.props.todos}
              incrementBy={10}
              pluralText='Sie sehen alle ${count} Einträge'
            />
            {/* eslint-enable */}
          </div>
        )}
      </div>
    )
  }
}
