import * as React from 'react'
import { ITodoSearchResult } from 'contracts/todos/interfaces/ITodoSearchResult'
import { observer } from 'mobx-react'
import { AppContext } from 'services/connection/models/AppContext'
import dayjs from 'dayjs'
import { RoundIcon } from 'components/RoundIcon'
import { hermes } from '@byll/hermes'
import { toast } from 'react-toastify'
import { box } from 'services/box'
import { getTicketStatusDe } from '../helpers/getTicketStatusDe'
import { TicketRating } from './TicketRating'
import { NotAuthorizedError } from 'contracts/errors/HermesErrors'

interface Props {
  ticket: ITodoSearchResult
  navigate: (path: string) => void
}

@observer
export class Ticket extends React.Component<Props, {}> {
  static contextType = AppContext

  private openEditTicketDialog = async () => {
    this.props.navigate(`tickets/${this.props.ticket.listId}/${this.props.ticket.id}`)
  }

  private deleteTicket = async () => {
    if (
      !(await box.alert(
        'Aufgabe löschen',
        'Möchten Sie diese Aufgabe wirklich unwiderruflich löschen?',
        { color: 'danger', cancel: 'Abbrechen', confirm: 'Löschen' },
      ))
    ) {
      return
    }
    try {
      await hermes.delete(
        `/api/${this.context.instance.id}/todoLists/${this.props.ticket.listId}/todos/${this.props.ticket.id}`,
      )
      toast.success('Ticket erfolgreich gelöscht.')
    } catch (e: any) {
      void box.alert(
        'Löschen fehlgeschlagen',
        e?.id === NotAuthorizedError.id
          ? 'Sie haben nicht die nötige Berechtigung, um diese Aufgabe zu löschen.'
          : 'Diese Aufgabe konnte nicht gelöscht werden.',
        { color: 'danger' },
      )
    }
  }

  render() {
    const { ticket: todo } = this.props
    const status = getTicketStatusDe(todo)

    return (
      <div
        key={todo.id}
        className='group hover:bg-indigo-100 bg-gray-100 rounded-md overflow-hidden relative cursor-pointer mb-3'
        onClick={this.openEditTicketDialog}
      >
        {/* Row 1 */}
        <div className='truncate pl-2 pr-2 py-2 z-1 flex items-center'>
          <input
            disabled
            type='checkbox'
            checked={!!todo.doneDate}
            className={`rounded-full ${
              todo.doneDate ? 'border-0' : 'border border-gray-300'
            } h-5 w-5 text-green-500 mr-2`}
          />
          {!todo.readConfirmation ? <strong>{todo.label}</strong> : todo.label}
          <span className='text-gray-400 ml-2 text-sm'>#{todo.id}</span>
          {this.props.ticket.listId === this.context.defaults.actionTodoListId && (
            <span className='rounded-full text-xs bg-cyan-400 text-white py-[1px] px-2 ml-2'>
              Maßnahme
            </span>
          )}
          {!todo.readConfirmation && (
            <span className='inline-flex rounded-full align-middle w-[9px] h-[9px] ml-1 bg-orange-600' />
          )}
          {todo.rating && (
            <div className='inline-flex ml-2'>
              <TicketRating key={todo.rating} rating={todo.rating} />
            </div>
          )}
          <RoundIcon
            classNameContainer='hidden group-hover:block'
            tooltip={{ text: 'Todo löschen', position: 'left' }}
            style={{ position: 'absolute', top: 5, right: 5 }}
            icon='fas fa-trash'
            color='danger'
            onClick={(event) => {
              event.stopPropagation()
              this.deleteTicket()
            }}
          />
        </div>

        {/* Row 2 */}
        <div className='text-gray-500 text-xs pl-[36px] pr-2 pb-2 -mt-2 truncate'>
          {status !== 'In Bearbeitung' && status !== 'Erledigt' && <span>{status}</span>}
          {status === 'In Bearbeitung' && (
            <span>{`Bestätigt von ${todo.assignees[0]?.name}`}</span>
          )}
          {status === 'Erledigt' && (
            <span>{`Erledigt am ${dayjs(todo.doneAt).format('DD.MM.YYYY')}`}</span>
          )}
        </div>
      </div>
    )
  }
}
